.timer_title{
    text-align: center;
    color:red;
    font-family: 'Quattrocento-Regular';
    font-size: 21px;
  }
  
  .timer_yellow{
    position: relative;
    margin-top: -30px;
    text-align: center;
    color:#FFCC00;
    font-family: 'Quattrocento-Bold';
    font-size: 90px;
  }
  
  .timer_container{
    width: 100%;
    height:410px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }


  .btn_container{
    position: absolute;
    background: red;
    height:80px;
    width: 100%;
    margin-top:-10px;
 
 
 }
  .button_text {
    position:relative;
    text-align: center;
    color:rgb(200, 195, 195)!important;
    font-family: 'Quattrocento-Bold';
    font-size: 24px;
    margin: 0;
    margin-top:20px;
  }

  .btn_mint{
    background: red!important;


  }
 