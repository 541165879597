@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@font-face {
  font-family: 'Quattrocento-Regular';
  src: local('Quattrocento'), url(../src/assets/fonts/Quattrocento-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Quattrocento-Bold';
  src: local('Quattrocento'), url(../src/assets/fonts/Quattrocento-Bold.ttf) format('opentype');
}
/*end of fonts*/

.body{
  margin: 0;
  padding: 0;
}

rfox-drop-kit::part(wallet-btn) {
  background: url(../src/assets/images/mint/button_connect_down.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
  padding: 20px;
}

rfox-drop-kit::part(wallet-btn-text) {
 
  
}

rfox-drop-kit::part(mint-btn) {

  
}

rfox-drop-kit::part(mint-btn-text) {
  
}

rfox-drop-kit::part(sold-out-btn) {
  background: url(../src/assets/images/mint/button_mint_down.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
  padding: 20px;
}

rfox-drop-kit::part(sold-out-text) {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #18E145;
  flex: none;
  order: 1;
  flex-grow: 0;
}


rfox-supply::part(supply-text) {
  font-family: 'Quattrocento-Regular';
  font-size: 16px;
  text-align: right;
  color: white;

}

rfox-countdown::part(countdown-text) {
  font-family: 'Quattrocento-Bold';
  font-size: 21px;
  text-align: left;
  color: white;
}

.mint{
  background: url('../src/assets/images/mint/bg_mint.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  padding: 20px;
}

.mintplaceholder{
  height: 547px!important;
  width: 547px!important;
  padding: 50px;
  margin-top: -90px;
  margin-left: 35px;
}

.white_list_mint_header{
  font-size: 50px;
  text-align: center;
  font-family: 'Quattrocento-Bold';
  color:white;
  margin-top: 10px;
}

.white_list_mint_text{
  font-size: 10px;
  text-align: center;
  font-family: 'Quattrocento-Regular';
  color:white;

}
.ghost_logo{
margin-left: 80px;
width: 350px;
margin-bottom: 50px;
margin-top: 30px;
}
.ghost_bg{
  height: 100vh;
  width: auto;
  margin-top: -50px;

}





.App {
  flexGrow: 1,
}
.body,html{
  background: black;
  margin:0;
  overflow-x: hidden; /* Hide scrollbars */
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-align: center;
  width: 100%!important;
}

.footer{
 
  background-color: #051F20;
  clear: both; 
  position: relative; 
  z-index: 1; 
  height: -2em; 
  margin-top: 2em; 

}




::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color:  #302103;;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #996600;
  border-radius: 100px;
}

.footer_text{
  font-family :  'Quattrocento-Bold';
  font-size: 26px;
  color:#cd9c3c;
  margin-top: 10px;
  margin-bottom: 10px;

}


.snowflake {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(white, white);
  border-radius: 50%;
  filter: drop-shadow(0 0 10px white);
}








