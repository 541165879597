.spacer_div{
    margin-top:10px;
    margin-bottom: 10px; 
  }

 .divider_img{
  height: 95px;
  width: auto;
  margin: auto;
 } 

 .divider_container{
  margin-top: 10px;
  position: relative;
  margin-left: 50px;
  margin-right: 50px;
  height: auto;
 }


 @media screen and (max-width: 480px) {
  .divider_img{
    height: 50px!important;
    width: 480px;
   
   } 
 } 

 @media screen and (max-width: 320px) {
  .divider_container{
    position: relative;
    top:75px;
   
   } 
 } 

 
  